import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { View } from '../payment-management/payment-management.interfaces';
import { DropdownListItemv2 } from '../vat-dropdown/vat-dropdown.component';

@Component({
  selector: 'app-fixed-amount-refund',
  templateUrl: './fixed-amount-refund.component.html',
  styleUrls: ['./fixed-amount-refund.component.scss'],
})
export class FixedAmountRefundComponent implements OnInit {
  constructor() {}
  @Output() setTaxRatePrecent = new EventEmitter<number>();

  @Input() totalRefundableAmount: number = 0;
  @Input() fixedAmountRefundForm: FormGroup = null;
  @Input() setView: (view: View) => void;
  @Input() taxRateOptions: DropdownListItemv2[];
  @Input() selectedTaxRate: DropdownListItemv2;
  fullRefund: boolean = false;
  showError: boolean = false;

  ngOnInit(): void {}

  setFullRefund(fullRefund: boolean): void {
    if (fullRefund) {
      this.fixedAmountRefundForm.controls.refundAmount.setValue(
        this.totalRefundableAmount.toString(),
        {
          emitEvent: false,
        }
      );
    }

    this.fullRefund = fullRefund;
  }

  setTaxRatePercent(option: number) {
    this.setTaxRatePrecent.emit(option);
  }

  async onSubmit() {
    if (this.fixedAmountRefundForm.valid) {
      this.setView(View.CONFIRM_FIXED_AMOUNT_REFUND);
    } else {
      this.showError = true;
    }
  }
}
