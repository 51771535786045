import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '@core/service/authentication.service';
import { ActivatedRoute, Router } from '@angular/router';
import {
  TokenVerificationError,
  VerificationTokenService,
  ZfbTokenType,
} from '@core/service/verification-token.service';
import { AppStateService } from '@core/service/app-state.service';
import { CompanyStatus, UserDetails } from '@core/dto/user-details';
import { ToastMessageService } from '@core/service/toast-message.service';
import { Market } from '@core/dto/Market';
import { LocaleService } from '@core/service/locale.service';

@Component({
  selector: 'app-token-entry',
  templateUrl: './token-entry.component.html',
  styleUrls: ['../login-pages.scss', './token-entry.component.scss'],
})
export class TokenEntryComponent implements OnInit {
  processingToken: boolean;
  formError = '';
  showError = false;
  loading = true;
  private defaultFormError = $localize`:@@tokenEntry.error.default:Ogiltig verifieringskod, försök igen.`;
  public email: string;
  public header: string;
  private tokenType: ZfbTokenType;
  public showResendSpinner: boolean;

  inputValue = '';

  trustThisDevice: boolean = null;
  modalActive = false;
  modalColor = '#EF3466';

  TWO_FACTOR_KEY = 'two_factor_authentication';
  isDeAtMarket: boolean = false;
  signupId: string;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private tokenService: VerificationTokenService,
    private appState: AppStateService,
    private toastService: ToastMessageService,
    public localeService: LocaleService
  ) {}

  ngOnInit() {
    this.header =
      this.route.snapshot.data.header && this.route.snapshot.data.header !== ''
        ? (this.header = this.route.snapshot.data.header)
        : $localize`:@@tokenEntry.header:Vänligen verifiera din e&#8209;postadress`;
    this.tokenType = this.route.snapshot.data.nextStep;
    this.signupId = this.route.snapshot.queryParams['signupId'];
    this.email = this.tokenService.email;

    this.isDeAtMarket =
      this.localeService.getCurrentLocaleMarket() === Market.DE ||
      this.localeService.getCurrentLocaleMarket() === Market.AT;
    this.loading = false;
  }

  submit() {
    if (this.processingToken) {
      return;
    }

    if (this.inputValue.length === 4) {
      if (
        this.tokenType === ZfbTokenType.TWO_FACTOR_AUTHENTICATION &&
        this.trustThisDevice === null
      ) {
        this.promptTrustModal();
        return;
      }

      this.disableForm();
      this.showError = false;
      this.formError = null;

      this.tokenService.setToken(this.inputValue, this.tokenType);
      this.tokenService.email = this.email;
      this.tokenService
        .verifyToken(this.tokenType, this.trustThisDevice)
        .then((response) => {
          if (!!response.success) {
            switch (this.tokenType) {
              case ZfbTokenType.PASSWORD_RESET:
                this.router.navigate(['/password-reset']);
                break;
              case ZfbTokenType.USER_CREATION:
                this.router.navigate(['/create-user']);
                break;
              case ZfbTokenType.EMAIL_ACCESS_RENEWAL:
                this.router.navigate(['/password-entry'], {
                  queryParams: { email: this.tokenService.email },
                });
                break;
              case ZfbTokenType.SIGNATORY_IDENTIFICATION:
                this.router.navigate(['/signup/auth'], {
                  queryParams: { email: this.tokenService.email, signupId: this.signupId },
                });
                // in case of partner signup, there is a partnersignupid in the params
                break;
              case ZfbTokenType.TWO_FACTOR_AUTHENTICATION:
                // Store the created authentication session id to be used in future logins
                localStorage.setItem(
                  this.TWO_FACTOR_KEY,
                  response.authenticationSessionId
                );

                this.appState.updateCurrentUser(response.userDetails);

                // find representation of companies that are approved
                const approved = this.appState
                  .getAvailableRepresentations()
                  .filter((rep) =>
                    rep.company.status.find(
                      (status) => status === CompanyStatus.APPROVED
                    )
                  );

                let result: Promise<UserDetails>;
                if (approved.length === 1) {
                  // if there is only one representation then select it automatically
                  result = this.authenticationService.switchProfile(
                    approved[0]
                  );
                  result.then(() =>
                    this.router.navigate(['/'], { skipLocationChange: false })
                  );
                } else {
                  this.router.navigate(['/'], { skipLocationChange: false });
                }
            }
          } else {
            this.formError = this.getError(response.errors);
            this.enableForm();
          }
        })
        .catch((error) => {
          this.formError = this.getError(error.error);
          this.enableForm();
        });
    } else {
      this.formError = this.defaultFormError;
      this.showError = true;
      this.enableForm();
    }
  }

  getError(errors: TokenVerificationError[]) {
    const error = errors[0];
    switch (error) {
      case TokenVerificationError.TOKEN_EXPIRED:
        return $localize`:@@tokenEntry.error.tokenExpired:Verifieringskoden är för gammal`;
      case TokenVerificationError.TOKEN_INVALID:
        return $localize`:@@tokenEntry.error.tokenInvalid:Verifieringskoden är ogiltig`;
    }
  }

  private enableForm() {
    this.processingToken = false;
  }

  private disableForm() {
    this.processingToken = true;
  }

  async resendCode() {
    try {
      this.showError = false;
      this.showResendSpinner = true;
      await this.tokenService.resendToken(this.email, this.tokenType);
      this.toastService.displaySuccess(
        $localize`:@@tokenEntry.toast.tokenResent:Verifieringskod skickad`
      );
    } catch (error: unknown) {
      this.toastService.displaySuccess(
        $localize`:@@tokenEntry.toast.tokenNotResent:Något gick fel`
      );
    }
    this.showResendSpinner = false;
  }

  back() {
    history.back();
  }

  promptTrustModal() {
    this.modalActive = true;
  }

  handleModalAction(trustThisDevice: boolean) {
    this.trustThisDevice = trustThisDevice;
    this.modalActive = false;
    this.submit();
  }
}
