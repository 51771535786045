import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  LineItemDomain,
  PaymentCaptureService,
  PaymentCaptureStatus,
  WidgetFixedAmountRefundsVM,
  WidgetRefundResponseVM,
  ZecStatus,
} from '@core/service/payment-capture.service';
import { environment } from '../../environments/environment';
import {
  PaymentManagementService,
  RefundableLineItem,
} from '../../services/payment-management.service';
import {
  LineItemVM,
  PaymentRequestStatusPil,
  PaymentRequestStatusPilData,
  QuantityChangeEvent,
  SimpleLineItemVM,
  View,
  WidgetContext,
} from './payment-management.interfaces';
import { Currency, CurrencyCode } from '@core/dto/CurrencyCode';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormValidators } from '@shared/validators/validators';
import { Market } from '@core/dto/Market';

@Component({
  selector: 'app-payment-management',
  templateUrl: './payment-management.component.html',
  styleUrls: ['./payment-management.component.scss'],
})
export class PaymentManagementComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private paymentCaptureService: PaymentCaptureService,
    private pms: PaymentManagementService
  ) {}

  public loading: boolean = true;
  public errorMessage: string = null;
  public languageLocale: string;

  public VIEW = View;
  public currentView = View.ORDER_DETAILS;
  public paymentRequestId: string;

  public selectedPreTaxAmount: number;
  public selectedAmountToCapture: number;
  public selectedTaxAmount: number;
  public availableForCapture: number;
  public paymentRequestTotalAmount: number;
  public uncapturedAmount: number;
  public capturedAmount: number;
  public deletedAmount: number;
  public initialPaymentRequestAmount: number;

  public capturedLineItems: SimpleLineItemVM[] = [];
  public lineItems: LineItemDomain[] = [];
  public lineItemsVM: LineItemVM[] = [];
  public selectedLineItems: LineItemVM[] = [];
  public deletedLineItems: SimpleLineItemVM[] = [];
  public refundedLineItems: SimpleLineItemVM[] = [];
  public refundableLineItems: RefundableLineItem[] = [];
  public uncapturedLineItems: SimpleLineItemVM[] = [];
  public fixedAmountRefundLineItems: SimpleLineItemVM[] = [];

  public allChecked: boolean = true;
  public editMode: boolean = false;

  public inProgress: boolean = false;
  public captureStatus: PaymentRequestStatusPil;
  public paymentMethod: string = '';

  private paymentCaptureStatus: PaymentCaptureStatus;

  public slask: boolean = false;
  public showError: boolean = false;
  public noStatusError: string = '';
  public context: WidgetContext;
  public lineItemRefunds: WidgetRefundResponseVM[];
  public fixedAmountRefunds: WidgetFixedAmountRefundsVM[];
  public pendingFixedAmountRefundLineItems: SimpleLineItemVM[];
  public pendingLineItemRefundLineItems: SimpleLineItemVM[];

  public latestCaptureTimeStamp: Date;
  public amountLeftToCapture: number;
  public refundedAmount: number;

  deTaxRateOptions = [
    { label: '19%', value: 19 },
    { label: '7%', value: 7 },
    { label: '0%', value: 0 },
  ];

  seTaxRateOptions = [
    { label: '25%', value: 25 },
    { label: '12%', value: 12 },
    { label: '6%', value: 6 },
    { label: '0%', value: 0 },
  ];
  public taxRateOptions = null;
  public fixedAmountTaxRatePercent: number = 0;
  public totalRefundableAmount: number = 0;
  public selectedAmountToRefund: number = 0;
  public selectedRefundableLineItems: RefundableLineItem[];
  public selectedRefundTaxAmount: number = 0;

  public fixedAmountRefundForm: FormGroup;
  public selectedFixedAmountRefundTaxAmount: number = 0;
  public currency: CurrencyCode = CurrencyCode.SEK;
  public market: Market = Market.DE;

  ngOnInit(): void {
    this.context = this.paymentCaptureService.context;

    this.currentView =
      this.context === WidgetContext.ZFB ? View.VIEW_ORDER : View.ORDER_DETAILS;
    let token = this.route.snapshot.queryParamMap.get('token');
    this.paymentCaptureService.setToken(token);
    this.paymentCaptureService.setApiUrl(environment.widgetApiUrl);

    this.paymentRequestId =
      this.route.snapshot.queryParamMap.get('paymentrequest');
    this.languageLocale = this.route.snapshot.queryParamMap.get('language');

    this.getPaymentStatus();
  }

  setupForm(paymentCaptureStatus: PaymentCaptureStatus): void {
    const {
      getCapturedLineItems,
      getUpdatedLineItems,
      getCapturableLineItems,
      toRefundableLineItem,
      toLineItemVM,
      getRefundableLineItems,
      getRefundedLineItems,
      getWidgetLineItemRefundResponseVM,
      getWidgetFixedAMountRefundResponseVM,
      fixedAmountToSimpleLineItem,
      fromLineItemJsontoSimpleLineItem,
    } = this.pms;
    this.market = paymentCaptureStatus.market;
    this.taxRateOptions =
      this.market === Market.SE ? this.seTaxRateOptions : this.deTaxRateOptions;
    this.fixedAmountTaxRatePercent = this.taxRateOptions[0].value;
    this.currency = paymentCaptureStatus.amount.currencyCode;
    this.paymentMethod = paymentCaptureStatus.paymentMethod;
    this.lineItems = paymentCaptureStatus.lineItems;
    this.availableForCapture = paymentCaptureStatus.availableForCapture.amount;
    this.paymentRequestTotalAmount = paymentCaptureStatus.amount.amount;
    this.capturedAmount = paymentCaptureStatus.capturedAmount.amount;
    this.capturedLineItems = getCapturedLineItems(this.lineItems);
    this.deletedLineItems = getUpdatedLineItems(this.lineItems);
    this.refundedLineItems = getRefundedLineItems(this.lineItems);
    this.getDeletedAmount();
    this.initialPaymentRequestAmount =
      paymentCaptureStatus.status === ZecStatus.CANCELLED
        ? paymentCaptureStatus.amount.amount
        : paymentCaptureStatus.amount.amount + this.deletedAmount;
    this.lineItemRefunds = getWidgetLineItemRefundResponseVM(
      paymentCaptureStatus.pendingLineItemRefunds
    );
    this.fixedAmountRefunds = getWidgetFixedAMountRefundResponseVM(
      paymentCaptureStatus.pendingFixedAmountRefunds
    );
    this.pendingLineItemRefundLineItems = fromLineItemJsontoSimpleLineItem(
      paymentCaptureStatus.pendingLineItemRefunds.reduce(
        (acc, item) => [...acc, ...item.refundLineItems],
        []
      )
    );
    this.pendingFixedAmountRefundLineItems = fixedAmountToSimpleLineItem(
      paymentCaptureStatus.pendingFixedAmountRefunds
    );
    this.latestCaptureTimeStamp = paymentCaptureStatus.latestCaptureTimeStamp;
    this.amountLeftToCapture =
      this.paymentRequestTotalAmount - this.capturedAmount;
    this.refundedAmount = paymentCaptureStatus.refunded || 0;

    this.allChecked = true;
    this.refundableLineItems = toRefundableLineItem(
      getRefundableLineItems(this.lineItems)
    );
    this.lineItemsVM = toLineItemVM(getCapturableLineItems(this.lineItems));
    this.uncapturedLineItems = this.toSimpleLineItem(this.lineItemsVM);
    this.fixedAmountRefundLineItems = fixedAmountToSimpleLineItem(
      paymentCaptureStatus.fixedAmountRefunds
    );

    this.totalRefundableAmount = this.pms.toTwoDecimals(
      this.capturedAmount - this.refundedAmount
    );

    this.fixedAmountRefundForm = new FormGroup({
      refundAmount: new FormControl('', [
        Validators.required,
        FormValidators.number(0.01, this.totalRefundableAmount),
        this.pms.onlyNumbersValidator,
      ]),
    });

    const refundedfixedAmountRefundControl =
      this.fixedAmountRefundForm.controls['refundAmount'];
    refundedfixedAmountRefundControl.valueChanges.subscribe((value) => {
      const numericValue = this.sanitizeInput(value);
      refundedfixedAmountRefundControl.setValue(numericValue, {
        emitEvent: false,
      });
      this.selectedFixedAmountRefundTaxAmount =
        numericValue -
        numericValue / ((100 + this.fixedAmountTaxRatePercent) / 100);
    });

    this.refundableLineItems.forEach((lineItem: RefundableLineItem) => {
      const { form } = lineItem;

      const toRefundControl = form.controls['toRefund'];
      const quantityControl = form.controls['quantity'];

      quantityControl.valueChanges.subscribe((value) => {
        if (!quantityControl.disabled) {
          const numericValue = this.sanitizeInput(value);
          quantityControl.setValue(numericValue, { emitEvent: false });
          toRefundControl.setValue(numericValue * lineItem.unitPrice, {
            emitEvent: false,
          });
        }
      });

      toRefundControl.valueChanges.subscribe((value) => {
        const numericValue = this.sanitizeInput(value);
        toRefundControl.setValue(numericValue, { emitEvent: false });
        quantityControl.setValue(1, { emitEvent: false });
        quantityControl.disable();
      });

      form.valueChanges.subscribe(() => {
        lineItem.unitPrice = toRefundControl.value / quantityControl.value;
        const unitPriceExcludingTax = this.pms.calcUnitPriceExcludingTax(
          lineItem.unitPrice,
          lineItem.taxRatePercent
        );

        lineItem.taxAmount = this.pms.calcTaxAmount(
          unitPriceExcludingTax,
          quantityControl.value,
          lineItem.taxRatePercent
        );

        this.getSelectedLineItems();
        this.getSelectedRefundLineItems();
      });
    });

    this.lineItemsVM.forEach((lineItem: LineItemVM): void => {
      const { form } = lineItem;

      const quantityControl = form.controls['quantity'];
      quantityControl.valueChanges.subscribe((value) => {
        const numericValue = this.sanitizeInput(value);
        quantityControl.setValue(numericValue, { emitEvent: false });
      });

      const unitPriceControl = form.controls['unitPrice'];
      unitPriceControl.valueChanges.subscribe((value) => {
        const numericValue = this.sanitizeInput(value);
        unitPriceControl.setValue(numericValue, { emitEvent: false });
      });

      form.valueChanges.subscribe(() => {
        const { unitPrice, quantity, taxRatePercent } = form.value;
        lineItem.totalAmount = this.pms.calcTotalAmount(unitPrice, quantity);

        lineItem.taxAmount = this.pms.calcTaxAmount(
          this.pms.calcUnitPriceExcludingTax(unitPrice, taxRatePercent),
          quantity,
          taxRatePercent
        );

        this.getSelectedLineItems();
        this.getSelectedRefundLineItems();
      });
    });

    this.getSelectedLineItems();
    this.getSelectedRefundLineItems();

    this.slask = this.lineItemsVM.length === 0 && this.availableForCapture > 0;

    this.captureStatus =
      this.setpaymentRequestCaptureStatus(paymentCaptureStatus);
  }

  getDeletedAmount(): void {
    this.deletedAmount = this.deletedLineItems.reduce(
      (totalAmount: number, item: SimpleLineItemVM) =>
        totalAmount + item.totalAmount,
      0
    );
  }

  async getPaymentStatus(): Promise<void> {
    this.loading = true;
    try {
      this.paymentCaptureStatus = await this.paymentCaptureService.getStatus(
        this.paymentRequestId || this.route.snapshot.queryParamMap.get('id')
      );
      this.setupForm(this.paymentCaptureStatus);
    } catch (err) {
      console.log(err);
      this.noStatusError = 'Could not get current status of payment';
    }
    this.loading = false;
  }

  getSelectedRefundLineItems(): void {
    const selectedItems = this.refundableLineItems.filter(
      (item: RefundableLineItem) => item.form.value.checked
    );

    const selectedAmount = this.pms.toTwoDecimals(
      selectedItems.reduce(
        (total: number, lineItem: RefundableLineItem) =>
          total + Number(lineItem.form.value.toRefund),
        0
      )
    );

    this.selectedRefundTaxAmount = this.pms.toTwoDecimals(
      selectedItems.reduce((acc, item) => acc + item.taxAmount, 0)
    );

    this.selectedAmountToRefund = selectedAmount;
    this.selectedRefundableLineItems = selectedItems;
  }

  getSelectedLineItems(): void {
    const selectedItems = this.lineItemsVM.filter(
      (item: LineItemVM) => item.form.value.checked
    );

    const selectedAmount = this.pms.toTwoDecimals(
      selectedItems.reduce(
        (total: number, lineItem: LineItemVM) => total + lineItem.totalAmount,
        0
      )
    );

    const capturedTaxAmount = this.pms.toTwoDecimals(
      selectedItems.reduce(
        (total: number, lineItem: LineItemVM) => total + lineItem.taxAmount,
        0
      )
    );

    this.selectedLineItems = selectedItems;
    this.selectedPreTaxAmount = selectedAmount - capturedTaxAmount;
    this.selectedAmountToCapture = selectedAmount;
    this.selectedTaxAmount = capturedTaxAmount;
    this.uncapturedAmount = this.availableForCapture - selectedAmount;

    this.showError =
      this.selectedAmountToCapture > this.paymentRequestTotalAmount ||
      this.selectedAmountToCapture < 0;
  }

  discardChanges(): void {
    this.setupForm(this.paymentCaptureStatus);
    if (this.currentView === View.PAYMENT_UPDATE) {
      this.selectAll(false);
    }
  }

  addLineItem(): void {
    const newlineItem: LineItemDomain = {
      id: null,
      title: '',
      itemType: null,
      description: '',
      captureState: [],
      quantity: 1,
      unitPrice: 0,
      taxRatePercent: 0,
      totalAmount: 0,
      taxAmount: 0,
      merchantMetadata: [],
      quantityUnit: 'unknown',
      deleted: false,
      originalState: '',
    };

    this.lineItemsVM = [
      ...this.lineItemsVM,
      ...this.pms.toLineItemVM(this.pms.getCapturableLineItems([newlineItem])),
    ];

    this.lineItemsVM.forEach((lineItem: LineItemVM) => {
      const { form } = lineItem;

      const quantityControl = form.controls['quantity'];
      quantityControl.valueChanges.subscribe((value) => {
        const numericValue = this.sanitizeInput(value);
        quantityControl.setValue(numericValue, { emitEvent: false });
      });

      const unitPriceControl = form.controls['unitPrice'];
      unitPriceControl.valueChanges.subscribe((value) => {
        const numericValue = this.sanitizeInput(value);
        unitPriceControl.setValue(numericValue, { emitEvent: false });
      });

      form.valueChanges.subscribe(() => {
        const { unitPrice, quantity, taxRatePercent } = form.value;

        lineItem.totalAmount = this.pms.calcTotalAmount(unitPrice, quantity);

        lineItem.taxAmount = this.pms.calcTaxAmount(
          this.pms.calcUnitPriceExcludingTax(unitPrice, taxRatePercent),
          quantity,
          taxRatePercent
        );

        this.getSelectedLineItems();
        this.getSelectedRefundLineItems();
      });
    });

    this.getSelectedLineItems();
    this.getSelectedRefundLineItems();
  }

  removeLineItem = (lineItemVM: LineItemVM): void => {
    this.lineItemsVM = this.lineItemsVM.filter((item) => item !== lineItemVM);
    this.getSelectedLineItems();
  };

  changeQuantity(quantityChangeEvent: QuantityChangeEvent): void {
    const quantityControl =
      quantityChangeEvent.lineItemVM.form.controls['quantity'];
    let currentValue = Number(quantityControl.value);

    if (quantityChangeEvent.value === 'ADD') {
      currentValue = Math.floor(currentValue + 1);
    }

    if (quantityChangeEvent.value === 'REMOVE') {
      currentValue = Math.ceil(currentValue - 1);
    }

    quantityControl.setValue(currentValue);
    this.getSelectedLineItems();
    this.getSelectedRefundLineItems();
  }

  selectAllRefundable(checked: boolean): void {
    this.allChecked = checked;
    this.refundableLineItems.map((item) =>
      item.form.controls['checked'].setValue(checked)
    );
  }

  selectAll(checked: boolean): void {
    this.allChecked = checked;
    this.lineItemsVM.map((item) =>
      item.form.controls['checked'].setValue(checked)
    );
  }

  setView = (view: View): void => {
    if (this.context === 'ZFB' && view === View.ORDER_DETAILS) {
      this.currentView = View.VIEW_ORDER;
      return;
    }

    if (
      (view === View.PARTIAL_CANCEL_ORDER || view === View.CONFIRM_CAPTURE) &&
      (this.lineItemsVM.find((lineItem) => lineItem.form.invalid) ||
        this.showError)
    ) {
      return;
    }

    if (view === View.ORDER_DETAILS) {
      this.discardChanges();
    }

    if (view === View.PARTIAL_CAPTURE) {
      this.selectAll(true);
    }

    this.errorMessage = '';
    this.currentView = view;
  };

  setEditMode(lineItemVM: LineItemVM): void {
    lineItemVM.editMode = !lineItemVM.editMode;
  }

  setRefundEditMode(lineItemVM: RefundableLineItem): void {
    lineItemVM.editMode = !lineItemVM.editMode;
  }

  onCreateRefund = async (
    selectedReason,
    note: string,
    refundType: 'FIXED_AMOUNT' | 'LINE_ITEM'
  ): Promise<void> => {
    this.inProgress = true;
    const valueOfLineItemRefund = this.selectedRefundableLineItems.reduce(
      (acc, item) => acc + Number(item.form.value.toRefund),
      0
    );

    const valueOfFixedAmountRefund =
      this.fixedAmountRefundForm.value.refundAmount;

    const refundAmount =
      refundType === 'LINE_ITEM'
        ? valueOfLineItemRefund
        : valueOfFixedAmountRefund;

    const refundCreationRequest = {
      paymentRequestId: this.paymentRequestId,
      value: { amount: refundAmount, currencyCode: this.currency },
      reason: selectedReason,
      notes: note,
      lineItems:
        refundType === 'LINE_ITEM'
          ? this.selectedRefundableLineItems.map((item) =>
              this.toRefunbleLineItem(item)
            )
          : null,
      taxRatePercent:
        refundType === 'FIXED_AMOUNT' ? this.fixedAmountTaxRatePercent : null,
    };
    try {
      const resp = await this.paymentCaptureService.createRefund(
        refundCreationRequest
      );

      // this is a hack to avoid creating a new refund endpoint for zfb.
      // if we get resp.amount we asume that it was the regular paymentCaptureStatus response
      // otherwise we need get status again to get a updated state
      if (!!resp.amount) {
        this.paymentCaptureStatus = resp;
        this.setupForm(this.paymentCaptureStatus);
      } else {
        await this.getPaymentStatus();
      }
      this.setView(View.ORDER_DETAILS);
      this.inProgress = false;
    } catch (err) {
      this.errorMessage = err.error;
      this.handleUnauthorizedError(err);
      this.inProgress = false;
    }
  };

  onApproveRefund = async (refundId: string) => {
    try {
      this.inProgress = true;
      this.paymentCaptureStatus =
        await this.paymentCaptureService.approveRefund(
          this.paymentRequestId,
          refundId
        );
      this.setupForm(this.paymentCaptureStatus);
      this.setView(View.ORDER_DETAILS);
      this.inProgress = false;
    } catch (err) {
      this.errorMessage = err.error;
      this.handleUnauthorizedError(err);
      this.inProgress = false;
    }
  };

  onCancelRefund = async (refundId: string) => {
    try {
      this.inProgress = true;
      this.paymentCaptureStatus = await this.paymentCaptureService.cancelRefund(
        this.paymentRequestId,
        refundId
      );
      this.setupForm(this.paymentCaptureStatus);
      this.setView(View.ORDER_DETAILS);
      this.inProgress = false;
    } catch (err) {
      this.errorMessage = err.error;
      this.handleUnauthorizedError(err);
      this.inProgress = false;
    }
  };

  onCreateFixedAmountRefund = async (
    refundValue: number,
    reason,
    note: string
  ): Promise<void> => {
    const refundCreationRequest = {
      paymentRequestId: this.paymentRequestId,
      value: { amount: refundValue, currencyCode: this.currency },
      reason: reason,
      notes: note,
    };
    this.paymentCaptureService.createRefund(refundCreationRequest);
  };

  toRefunbleLineItem(lineItem: RefundableLineItem) {
    return {
      description: lineItem.form.value.description,
      lineItemId: lineItem.id,
      refundAmount: Number(lineItem.form.value.toRefund),
      refundQuantity: lineItem.form.controls.quantity.value,
      refundTaxAmount: lineItem.taxAmount,
      refundTaxRatePercentage: lineItem.taxRatePercent,
      unitAmount: lineItem.unitPrice,
    };
  }

  onCapture = async (): Promise<void> => {
    this.inProgress = true;
    try {
      const selectedLineItems: LineItemDomain[] = this.pms.toLineItemDto(
        this.lineItemsVM.filter((item) => !!item.form.value.checked)
      );

      this.paymentCaptureStatus =
        await this.paymentCaptureService.captureLineItems(
          this.paymentRequestId,
          this.amountToCurrency(this.selectedAmountToCapture),
          selectedLineItems
        );

      this.setupForm(this.paymentCaptureStatus);
      this.errorMessage = null;
      this.setView(View.ORDER_DETAILS);
      this.inProgress = false;
    } catch (err) {
      this.errorMessage = err.error;
      this.handleUnauthorizedError(err);
      this.inProgress = false;
    }
  };

  setpaymentRequestCaptureStatus(
    paymentCaptureStatus: PaymentCaptureStatus
  ): PaymentRequestStatusPil {
    const paymentRequestStatusPilData: PaymentRequestStatusPilData = {
      uncaptured: {
        text: 'Uncaptured',
        color: '#fef4e5',
        textColor: '#faa900',
      },
      partiallyCaptured: {
        text: 'Partially captured',
        color: '#faa900',
        textColor: '#fffff',
      },
      expired: { text: 'Expired', color: '#F8F9FB', textColor: '#6A7381' },
      captured: { text: 'Captured', color: '#E5F5F2', textColor: '#009773' },
      cancelled: { text: 'Cancelled', color: '#e6e9ef', textColor: '#343b45' },
      open: {
        text: 'Open',
        color: '#DBE7FF',
        textColor: '#4466EE',
      },
    };

    const { status, capturedAmount } = paymentCaptureStatus;

    if (status === ZecStatus.CREATED) {
      return paymentRequestStatusPilData.open;
    }

    if (status === ZecStatus.CANCELLED) {
      return paymentRequestStatusPilData.cancelled;
    }

    if (status === ZecStatus.EXPIRED) {
      return paymentRequestStatusPilData.expired;
    }

    if (status === ZecStatus.SETTLED || this.slask) {
      return paymentRequestStatusPilData.captured;
    }

    if (
      status === ZecStatus.PENDING_MERCHANT_SIGN_OFF &&
      capturedAmount.amount > 0
    ) {
      return paymentRequestStatusPilData.partiallyCaptured;
    }

    if (
      status === ZecStatus.PENDING_MERCHANT_SIGN_OFF &&
      capturedAmount.amount === 0
    ) {
      return paymentRequestStatusPilData.uncaptured;
    }
  }

  getUpdatedLineItems(): LineItemDomain[] {
    const selectedItems = this.lineItemsVM.filter(
      (item) => item.form.controls['checked'].value
    );

    selectedItems.forEach((item) => {
      const quantity = Number(item.form.controls['quantity'].value);
      const initialQuantity = item.initialQuantity;
      const totalAmount = item.totalAmount;
      const maxUnitPrice = item.maxUnitPrice;

      if (quantity < initialQuantity) {
        item.form.controls['quantity'].setValue(initialQuantity - quantity);
      } else if (quantity === 1 && totalAmount < maxUnitPrice) {
        item.form.controls['unitPrice'].setValue(maxUnitPrice - totalAmount);
        item.totalAmount = item.form.controls['unitPrice'].value;
      }
    });

    return this.pms.getUpdatedLineItemsFromVMs(this.lineItems, selectedItems);
  }

  setZecStatus(updatedAmount: number): ZecStatus {
    if (updatedAmount === 0) {
      return ZecStatus.CANCELLED;
    }

    if (
      this.selectedAmountToCapture + this.capturedAmount ===
      this.paymentRequestTotalAmount
    ) {
      return ZecStatus.SETTLED;
    }

    return ZecStatus.PENDING_MERCHANT_SIGN_OFF;
  }

  onUpdate = async (): Promise<void> => {
    this.inProgress = true;
    try {
      const updatedLineItems: LineItemDomain[] = this.getUpdatedLineItems();
      const updatedAmount = updatedLineItems.reduce(
        (acc, item) => acc + item.totalAmount,
        0
      );

      this.paymentCaptureStatus =
        await this.paymentCaptureService.updateLineItems(
          this.paymentRequestId,
          this.amountToCurrency(updatedAmount),
          updatedLineItems,
          this.setZecStatus(updatedAmount)
        );

      this.setupForm(this.paymentCaptureStatus);

      this.errorMessage = null;
      this.setView(View.ORDER_DETAILS);
      this.inProgress = false;
    } catch (err) {
      this.errorMessage = err.error;
      this.handleUnauthorizedError(err);
      this.inProgress = false;
    }
  };

  showPaymentUpdate = (): void => {
    this.selectAll(false);
    this.setView(View.PAYMENT_UPDATE);
  };

  setTaxRatePrecent(quantityChangeEvent: QuantityChangeEvent): void {
    quantityChangeEvent.lineItemVM.form.controls['taxRatePercent'].setValue(
      quantityChangeEvent.value
    );
  }

  setFixedAmountTaxRatePrecent(taxRatePercent: number) {
    this.fixedAmountTaxRatePercent = taxRatePercent;
  }

  toSimpleLineItem(lineItemsVM: LineItemVM[]): SimpleLineItemVM[] {
    return lineItemsVM.map((item) => {
      const { title, quantity, taxRatePercent, unitPrice } = item.form.value;
      return {
        title,
        quantity,
        taxAmount: item.taxAmount,
        totalAmount: item.totalAmount,
        unitPrice,
        taxRatePercent,
      };
    });
  }

  amountToCurrency = (amount: number): Currency => ({
    amount,
    currencyCode: this.currency,
  });

  removeAllItems = (): void => {
    this.selectAll(true);
    this.onUpdate();
  };

  handleUnauthorizedError(error) {
    if (error.status === 403) {
      this.errorMessage = 'unauthorized';
    }
  }

  sanitizeInput(input) {
    // Replace anything that's not a number or dot with a blank string
    const sanitized = input.toString().replace(/[^0-9.-]/g, '');

    // Return the sanitized string
    return sanitized;
  }
}
